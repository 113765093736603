import '../../scss/login.scss';

/** GROUP SWITCHER */
const groupCheckbox = document.querySelector('.login-switcher .group-switcher input[type="checkbox"]');

if (groupCheckbox) {
    groupCheckbox.addEventListener('change', () => document.location.href = groupCheckbox.checked ?
        groupCheckbox.dataset.proPath :
        groupCheckbox.dataset.partPath
    );
}